import React from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { observer } from "mobx-react";
import { RootPage } from "../modules/root/pages/RootPage";
import { Route, useRouteNavigator } from "../routes/RoutesHelper";
import { useTranslation } from "react-i18next";
import {
  ErrorLayout,
  ErrorPage,
  InternalServerErrorDarkIllustration,
  InternalServerErrorLightIllustration,
  NetworkErrorDarkIllustration,
  NetworkErrorLightIllustration,
  PageNotFoundDarkIllustration,
  PageNotFoundLightIllustration,
  RootContainer,
} from "@pulse/shared-components";
import { isBrowserLightMode } from "@pulse/shared-components";
import {
  ButtonProps,
  LoadingIndicator,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";

const errorRoutes = (): RouteObject[] => {
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const isLightMode = isBrowserLightMode();
  const tokens = useFoundationColorTokens();

  const notFoundErrorPage = (
    <ErrorPage
      title={t("surveyRespondentErrors.surveyRespondentPageNotFoundTitle")}
      description={t(
        "surveyRespondentErrors.surveyRespondentPageNotFoundDescription",
      )}
      icon={
        isLightMode ? (
          <PageNotFoundLightIllustration themeTokens={tokens} />
        ) : (
          <PageNotFoundDarkIllustration themeTokens={tokens} />
        )
      }
    />
  );

  const pageNotFound: RouteObject = {
    path: Route.PageNotFound,
    element: notFoundErrorPage,
  };

  const internalServerError: RouteObject = {
    path: Route.InternalServerError,
    element: (
      <ErrorPage
        title={t(
          "surveyRespondentErrors.surveyRespondentInternalServerErrorTitle",
        )}
        description={t(
          "surveyRespondentErrors.surveyRespondentInternalServerErrorDescription",
        )}
        icon={
          isLightMode ? (
            <InternalServerErrorLightIllustration themeTokens={tokens} />
          ) : (
            <InternalServerErrorDarkIllustration themeTokens={tokens} />
          )
        }
        buttonProps={
          {
            buttonLabel: t("surveyRespondentErrors.backToPreviousPageLabel"),
            onClick: (): void => {
              navigateTo.parent();
            },
          } as ButtonProps
        }
      />
    ),
  };

  const networkError: RouteObject = {
    path: Route.NetworkError,
    element: (
      <ErrorPage
        title={t("surveyRespondentErrors.surveyRespondentTimeOutTitle")}
        description={t(
          "surveyRespondentErrors.surveyRespondentTimeOutDescription",
        )}
        icon={
          isLightMode ? (
            <NetworkErrorLightIllustration themeTokens={tokens} />
          ) : (
            <NetworkErrorDarkIllustration themeTokens={tokens} />
          )
        }
        buttonProps={
          {
            buttonLabel: t("surveyRespondentErrors.backToPreviousPageLabel"),
            onClick: (): void => {
              navigateTo.parent();
            },
          } as ButtonProps
        }
      />
    ),
  };

  const pathNotFound: RouteObject = {
    path: Route.PathNotFound,
    element: notFoundErrorPage,
  };

  return [
    {
      path: Route.Root,
      element: <ErrorLayout />,
      children: [pageNotFound, internalServerError, pathNotFound, networkError],
    },
  ];
};

const getRoutes = (): RouteObject[] => {
  return [
    {
      path: "/",
      element: <RootPage />,
    },
    {
      path: Route.SurveyLive,
      element: <RootPage />,
    },
    {
      path: Route.SurveyPreview,
      element: <RootPage />,
    },
    {
      path: Route.GenericLink,
      element: (
        <RootContainer>
          <LoadingIndicator isLoading={true} />
        </RootContainer>
      ),
    },
    ...errorRoutes(),
  ];
};

export const Router = observer((): React.ReactElement | null => {
  const routes = getRoutes();

  return useRoutes(routes);
});
